angular.module('ABSServiceApp.controllers.PfHolzpellets', [])
.controller('PfHolzpelletsController', function($scope, $rootScope, $window, localStorageService, $http) {
	$http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
	/* Datenmodell */	
	$scope.steps = {
			first: 1,
			last: 7,
			Kundentyp: 1,
			Aufstellung: 2,
			Lagermenge: 3,
			Raummasse: 4,
			Kesseltyp: 5,
			Entnahme: 6,
			Ergebnis: 7
	};
	$scope.list = {
		Kesseltypen	: {},
		Entnahmen	: {},
		Hits		: {},
		HitsCount	: 0
	};
	$scope.data = {
			step: $scope.steps.first,
			kundentyp: 0,
			ort: 0,
			raum: {
				laenge: null,
				breite: null,
				hoehe: null
			},
			kessel: {
				id: 0,
				name: ""
			},
			kesselleistung: 0,
			entnahme: {
				id: 0,
				bezeichnung: ''
			},
			lagermenge: 0.0
	};
	$scope.httpConfig = {
			//"Content-Type": "application/json; charset=UTF-8;"
			"Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
	};
	/* INIT */
	/* beim laden des Formulars sollen die alten Werte wieder hergestellt werden */
	var data = localStorageService.get("pfHolzpellets");
	if ( data != undefined && data.length > 0 ) { $scope.data = angular.fromJson(data); }
	
	/* vor und zurück */
	$scope.previous = function() {
		
		/* Wenn wir vom Ergebnis kommen, und "außen" gewählt hatten, dann müssen wir hier zur Tonnage zurück */
		if ( $scope.data.step == $scope.steps.Ergebnis 
				&& $scope.data.ort == 2 ) {
			$scope.data.step = $scope.steps.Lagermenge;
		} else {
			$scope.data.step--;
		}
		
		/* achten, dass wir nicht zuweit zurück gehen*/
		if ( $scope.data.step < 1 ) { $scope.data.step = 1; }
		
		$rootScope.$broadcast("setInfo",$scope.data.step + " / " + $scope.data.lastStep);
	};
	
	$scope.next = function(pStep, pFrm) {
		/* aktuellen Schritt validieren */
		var valid = true;
		if ( pStep !== undefined ) {
			switch (pStep) {
				case $scope.steps.Lagermenge: {
					valid = $scope.data.lagermenge == 0 ||
					$scope.data.lagermenge == "" ? false : true;
					pFrm.fldLagermenge.$setValidity('keineLagermenge', valid);
					break;
				}
				case $scope.steps.Raummasse: {
					pFrm.fldLaenge.$setValidity('keineLaenge', 
							($scope.data.raum.laenge == null || $scope.data.raum.laenge == 0 ? false : true));
					pFrm.fldBreite.$setValidity('keineBreite', 
							($scope.data.raum.breite == null || $scope.data.raum.breite == 0 ? false : true));
					pFrm.fldHoehe.$setValidity('keineHoehe', 
							($scope.data.raum.hoehe == null || $scope.data.raum.hoehe == 0 ? false : true));
					valid = $scope.data.raum.breite == null ||  
				 			$scope.data.raum.laenge == null || 
				 			$scope.data.raum.hoehe  == null ? false : true;
					break;
				}
				case $scope.steps.Kesseltyp: {
					pFrm.fldTyp.$setValidity('keinKesselTyp', 
							($scope.data.kessel.id == null || $scope.data.kessel.id == "" ? false : true));
					pFrm.fldLeistung.$setValidity('keineLeistung', 
							($scope.data.kesselleistung == null || $scope.data.kesselleistung == 0 ? false : true));
					valid = $scope.data.kessel.id == "" ||
							$scope.data.kesselleistung 	 == null ? false : true;
					break;
				}
				case $scope.steps.Entnahme: {
					valid = $scope.data.entnahme.id == 0 || $scope.data.entnahme.id == "" ? false : true;
					pFrm.fldEntnahme.$setValidity('keineEntnahme', valid);
					break;
				}
			}
		}
		if ( !valid ) { return false; }
				
		/* weiter zum nächsten Schritt */
		$scope.data.step++;		
		
		/* achten, dass wir nicht zuweit nach vorne gehen */
		if ( $scope.data.step > $scope.steps.last ) { $scope.data.step = $scope.steps.last; }
	
		/* Wenn "aussen" gewählt wurde, dann noch die Tonnage abfragen, und dann zum Ergebnis! */
		if ( pStep == $scope.steps.Lagermenge 
				&& $scope.data.ort == 2 ) {
			$scope.data.step = $scope.steps.Ergebnis;
		}

		/* werte speicehrn (test) */
		localStorageService.set("pfHolzpellets", angular.toJson($scope.data));
		
		$rootScope.$broadcast("setInfo",$scope.data.step + " / " + $scope.steps.last);
	};
	
	/* Methoden zum Setzen der Werte */
	$scope.setKundentyp 	= function(pTyp) {
		$scope.data.kundentyp = pTyp;
		$scope.next();
	};
	
	$scope.setOrt			= function(pOrt) {
		$scope.data.ort = pOrt;
		$scope.next();
	};
	
	$scope.find				= function() {
		/* Ergebnis anzeigen */
		$http.post('php/getData.php', 
					$.param({'action'		: 'getHPResults', 
					 		 'ort'	 		: $scope.data.ort,
							 'entnahmeid' 	: $scope.data.entnahme.id,
							 'lagermenge'	: $scope.data.lagermenge,
							 'raum'	 		: $scope.data.raum,
							 'kesselid'		: $scope.data.kessel.id}), 
					$scope.httpConfig)
			.success(function(pResp, pStatus, pHeaders, pConfig) {
				//console.log(pResp);
				$scope.list.HitsCount 		= pResp.count;
				$scope.list.Hits 			= pResp.data;
				$scope.list.Recomms 		= pResp.recomms 
			})
			.error(function(pResp, pStatus, pHeaders, pConfig) {
				console.log('Fehler!');
				$scope.list.HitsCount 	= 0;
				$scope.list.Hits 		= null;
				$scope.list.Recomms		= null; 
			});
	}
	
	$scope.getKesseltypen	= function() {
		$http.post('php/getData.php', $.param({'action': 'getListKesseltyp'}))
		.success(function(pResp, pStatus, pHeaders, pConfig) {
			//console.log(pResp);
			$scope.list.Kesseltypen = pResp;
		})
		.error(function(pResp, pStatus, pHeaders, pConfig) {
			console.log('Fehler! %o', pResp);
			$scope.list.Kesseltypen = null;
		});
	}

	$scope.getEntnahmen	= function() {
		$http.post('php/getData.php', $.param({'action': 'getListEntnahme', 
											   'kesselId': $scope.data.kessel.id,
											   'leistung': $scope.data.kesselleistung}), $scope.httpConfig)
		.success(function(pResp, pStatus, pHeaders, pConfig) {
			console.log(pResp);
			$scope.list.Entnahmen = pResp;
		})
		.error(function(pResp, pStatus, pHeaders, pConfig) {
			console.log('FEHLER! %o', pResp);
			$scope.list.Entnahmen = null;
		});
	}
	
	/* wichtig: nach dem Senden den Datenspeicher leeren... */
	//localStorageService.remove("pfHolzpellets");
});