var ctrProducts = angular.module('ABSServiceApp.controllers.Produkte', []);

ctrProducts.controller('ProductsController', ['$scope', '$http', 'Products', function($scope, $http, Products) {
	$scope.products = Products;
	$scope.load = function() {
		$http({
			method: 'POST',
			url: '/php/products.php'
		});
	};
}]);