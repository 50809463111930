angular.module('ABSServiceApp.controllers.Produktfinder', [])
.controller('ProduktfinderController', function($scope, $window) {
	$scope.step = 1;
	$scope.lastStep = 13;
	
	/* die Werte */
	$scope.bereich		= 0; /*1=Landwirtschaft, 2=Holzpellets, 3=Industrie*/
	$scope.kundentyp 	= 0; 
	$scope.anforderung 	= 0;
	$scope.ort			= 0;
	$scope.raum			= {
			laenge: 0,
			breite: 0,
			hoehe: 0
	};
	$scope.produkt		= 0;
	$scope.kessel		= {
			typ: "",
			leistung: 0
	}
	
	/* vor und zurück */
	$scope.previous = function() {
		$scope.step--;
		/* Wenn wir hier von Schritt Nr. 5 kommen, müssen wir auf den Aufstellungsort achten...
		 * Wenn dieser 1 ist, dann Schritt 5 überspringen und mit 4 weiter machen */
		if ( $scope.step == 4 && $scope.ort == 1 ) { $scope.step--; }
		/* achten, dass wir nicht zuweit zurück gehen*/
		if ( $scope.step < 1 ) { $scope.step = 1; }
	};
	
	$scope.next = function() {
		$scope.step++;
		/* achten, dass wir nicht zuweit nach vorne gehen*/
		if ( $scope.step > $scope.lastStep ) { $scope.step = $scope.lastStep; }
	};
	
	/* Methoden zum Setzen der Werte */
	$scope.gotoBereich		= function(pBereich) {
		switch ( pBereich ) {
			case 1: {
				$window.location.href = '#/pf_landwirtschaft';
				break;
			}
			case 2: {
				$window.location.href = '#/pf_holzpellets';
				break;
			}
			case 3: {
				$window.location.href = '#/pf_industrie';
				break;
			}
			default: {
				/* ungültiger Bereich */
				return false;
			}
		}
	}
	$scope.setBereich		= function(pBereich) {
		$scope.bereich = pBereich;
	}
	
	$scope.setKundentyp 	= function(pTyp) {
		$scope.kundentyp = pTyp;
		$scope.next();
	};
	
	$scope.setAnforderung 	= function(pOption) {
		$scope.anforderung ^= pOption;
	};
	
	$scope.isAnforderung 	= function(pOption) {
		return ( ($scope.anforderung & pOption) == pOption ? true : false);
	}
	
	$scope.setOrt			= function(pOrt) {
		$scope.ort = pOrt;
		/* bei Auswahl "innen" können wir den nächsten Schritt überspringen */
		if ( $scope.ort == 1 ) { $scope.step++; }
		$scope.next();
	}
	
	$scope.setProdukt		= function(pProdukt) {
		$scope.produkt ^= pProdukt;
	}
	
	$scope.isProdukt		= function(pProdukt) {
		return ( ($scope.produkt & pProdukt) == pProdukt ? true : false);
	}
});