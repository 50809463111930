angular.module("ABSServiceApp.directives.loadingIndicator", [])
.directive("loadingIndicator", function() {
	return {
        restrict : "A",
        template: "<p>Lade Daten...</p>",
        link : function(scope, element, attrs) {
            scope.$on("loading-started", function(e) {
                element.css({"display" : ""});
            });

            scope.$on("loading-complete", function(e) {
                element.css({"display" : "none"});
            });

        }
    };
});