angular.module("ABSServiceApp.directives.appCacheUpdate", [])
.directive("appCacheUpdate", function() {
	var elementScope;
	
	window.applicationCache.onupdateready = function() {
		console.log('updateready...!');
		if (window.applicationCache.status === window.applicationCache.UPDATEREADY) {
            window.applicationCache.swapCache();
            elementScope.show = true;
            if (!elementScope.$$phase) {
                elementScope.apply(elementScope.show);
            }
        };
	};
	
	return {
		restrict: "E" /* nur als Element zulassen */,
		template: '<div data-role="popup" class="app-cache-update-popup ui-content">'+
					'<p>Neue Version verfügbar!</p>'+
				  '</div>',
		scope: {} /* eigener Scope mit true */,
		controller: function ($scope) {
			$scope.$watch("show", function(pShow) {
				if ( !pShow ) { return; }
				$(".app-cache-update-popup").popup("open",{});
			})
			$scope.show = false;
		},
		link: function(pScope, pElement, pAttr) {
			elementScope = pScope;
			console.log('---app-cache-update---');
			console.log(pScope, pElement, pAttr);
		}
	}
});