angular.module("ABSServiceApp.services.App", [])
.service("AppService", function($rootScope, $location, localStorageService) {
	/* speichert den aktuellen Pfad, damit man diesen wiederherstellen kann*/
	function saveState(pEvent, pData) {
		localStorageService.set('lastPath', $location.$$path);
		if ( $location.$$path == "/" ) {
			/* die Daten aufräumen */
			localStorageService.set('pfHolzpellets','');
		}
	};
	
	/* stellt den letzten Status der App wieder her */
	function restoreState(pEvent, pData) {
		var path = localStorageService.get('lastPath');
		if ( path != null && path !== undefined && path.length > 0 ) {
			window.location.href = "#" + path;
		};
	};
	
	function pageShow(pEvent, pData) {
		console.log('pageShow', pEvent, pData);
	};
	
	function updateReady(pEvent) {		
		//var loader = $('body').data("loadingIndicator");
		//loader.show();
		if (window.applicationCache.status === window.applicationCache.UPDATEREADY) {
            window.applicationCache.swapCache();     
            window.location.href = "#/updateInfo";
        };
        //loader.hide();
	}
	
	function setInfo(pInfo) {
		console.log(pInfo);
	}
	
	/* Events abfangen ($broadcast) */
	$rootScope.$on("saveState", saveState);
	$rootScope.$on("restoreState", restoreState);
	$rootScope.$on("pageShow", pageShow);
	$rootScope.$on("updateReady", updateReady);
	$rootScope.$on("setInfo", setInfo);
    //$rootScope.$on('$stateChangeSuccess', function(evt, newUrl, newPar, oldUrl, oldPar) {});
});