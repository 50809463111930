angular.module('ABSServiceApp', [
  /* depending modules */
  'ngRoute',
  'mobile-angular-ui',
  'pdf',
  'videosharing-embed',
  'LocalStorageModule',
  'angularModalService',
  /* Services */
  'ABSServiceApp.services.App',
  /* Factories */
  'ABSServiceApp.factory.Products',
  /* Controllers */
  'ABSServiceApp.controllers.App',
  'ABSServiceApp.controllers.PDF',
  'ABSServiceApp.controllers.Youtube',
  'ABSServiceApp.controllers.Produkte',
  'ABSServiceApp.controllers.Produktfinder',
  'ABSServiceApp.controllers.PfHolzpellets'
])
.config(function(localStorageServiceProvider, $routeProvider, $httpProvider){
  $('body').loadingIndicator(); /* init LoadingIndicator */
  /* der lokale Speicher erhält zur Unterscheidung ein Präfix */
  localStorageServiceProvider.setPrefix('ABS');
  /* die Routen definieren */
  $routeProvider.when('/', 					{templateUrl:'home.html',  reloadOnSearch: false});
  $routeProvider.when('/anruf', 			{templateUrl:'anruf.html',  reloadOnSearch: false});
  $routeProvider.when('/disclaimer', 		{templateUrl:'disclaimer.html',  reloadOnSearch: false});
  $routeProvider.when('/holzpellets', 		{templateUrl:'holzpellets.html',  reloadOnSearch: false});
  $routeProvider.when('/industrie', 		{templateUrl:'industrie.html',  reloadOnSearch: false});
  $routeProvider.when('/sonder', 			{templateUrl:'sonder.html',  reloadOnSearch: false});
  $routeProvider.when('/kontakt', 			{templateUrl:'kontakt.html',  reloadOnSearch: false});
  $routeProvider.when('/landwirtschaft', 	{templateUrl:'landwirtschaft.html',  reloadOnSearch: false});
  $routeProvider.when('/pf_holzpellets', 	{templateUrl:'pf_holzpellets.html',  reloadOnSearch: false});
  $routeProvider.when('/pf_landwirtschaft', {templateUrl:'pf_landwirtschaft.html',  reloadOnSearch: false});
  $routeProvider.when('/pf_industrie', 		{templateUrl:'pf_industrie.html',  reloadOnSearch: false});
  $routeProvider.when('/produkte', 			{templateUrl:'produkte.html',  reloadOnSearch: false});
  $routeProvider.when('/produktfinder', 	{templateUrl:'pf_start.html',  reloadOnSearch: false});
  $routeProvider.when('/pdfviewer', 		{templateUrl:'pdfviewer.html',  reloadOnSearch: false});
  $routeProvider.when('/updateInfo', 		{templateUrl:'updateInfo.html',  reloadOnSearch: false});
  $routeProvider.when('/youtube', 			{templateUrl:'youtube.html',  reloadOnSearch: false});
  /* wenn nichts passt, dann auf HOME */
  $routeProvider.otherwise({redirectTo: '/'});
  /* Loading-Indicator */
  $httpProvider.interceptors.push(function($q, $rootScope) {
      return {
          'request': function(config) {
              	//$rootScope.$broadcast('loading-started');
              	var loader = $('body').data("loadingIndicator");
      			loader.show();
      			return config || $q.when(config);
          },
          'response': function(response) {
    			//$rootScope.$broadcast('loading-complete');
            	var loader = $('body').data("loadingIndicator");
      			loader.hide();
      			return response || $q.when(response);
          }
      };
  });
})
.run(function($rootScope, $location, localStorageService, AppService) {
	//$rootScope.$on("$routeChangeError", function(pEvt, pNext, pCurr) {});
	//$rootScope.$on("$routeChangeStart", function(pEvt, pNext, pCurr) {});
	//window.onbeforeunload 	= function(pEvent) {};
	//window.onload 			= function(pEvent) {};
	//window.onpageshow			= function(pEvent) {}
	window.applicationCache.onupdateready = function() {$rootScope.$broadcast("updateReady");};
});
/* some jQuery-magic */
$(function() {
	$('#unload').click(function(){
		$(window).trigger('beforeunload');
	});
	$.stayInWebApp();
});