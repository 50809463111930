angular.module('ABSServiceApp.controllers.App', [])
.controller('AppController', function($scope, $rootScope, $location, localStorageService, $window){
  /* beim Start der Anwendung ein Restore ausführen */
  $rootScope.$broadcast("restoreState");
  $scope.version	= '2.4';
  $scope.info	  	= '';
  $scope.location 	= $location;
  $scope.appDebug 	= false;
  $scope.titel 		= 'AppController';
  $scope.appName	= 'A.B.S. Silo App';
  $scope.company =  {
		  name		: 'A.B.S. Silo- und Förderanlagen GmbH', 
		  street	: 'Industriepark 100',
		  location	: '74706 Osterburken',
		  phone		: '+49 6291 6422-0',
		  fax		: '+49 6291 6422-50',
		  www		: 'www.abs-silos.de',
		  email		: 'info@abs-silos.de',
		  mailto	: 'mailto:info@abs-silos.de?subject=Kontakt von der ' + $scope.appName
  };
  
  $scope.getAddress = function() {
	  var c = $scope.company;
	  return c.street + '<br/>' + c.location + '<br/>';
  };
  
  $scope.setActualPath	= function() {
	  $rootScope.$broadcast("saveState");
  }

  $scope.showUpdateInfo = function() {
	  $rootScope.Ui.turnOn('infoMsg');
  }
  
  $scope.reload = function() {
	  /* die alte Seite herstellen */
	  var path = localStorageService.get('lastPath');
   	  if ( path == undefined || path.length == 0 ) { path = '/'; };
	  window.location.href = "#" + path;
	  
	  /* und reload damit das Update greift... */
	  window.location.reload();
  }
  
  $scope.doCall = function() {
	  window.location.href = 'tel:0049629164220';
  }
  
  $scope.isHome = function() {
	  return $location.path() == "/" ? true : false;
  }
  $scope.developState = function() {
	  alert('Funktion noch im Aufbau!');
  }
  $scope.goto = function(pTarget) {
//	  $window.alert('goto..');
	  $window.open(pTarget,'_blank','location=yes');
	  //window.location.href = pTarget;
  }
});