angular.module('ABSServiceApp.controllers.PDF', [])
/*
.controller('PDFController', [ '$scope', 'PDFViewerService', function($scope, pdf) {
	$scope.titel = 'PDFController';
	$scope.viewer = pdf.Instance("viewer");

	$scope.nextPage = function() {
		$scope.viewer.nextPage();
	};

	$scope.prevPage = function() {
		$scope.viewer.prevPage();
	};

	$scope.pageLoaded = function(curPage, totalPages) {
		$scope.currentPage = curPage;
		$scope.totalPages = totalPages;
	};
}]);
*/
.controller('DocCtrl', function($scope) {
	  $scope.pdfUrl = './pdf/test.pdf';
});